export const facts = [
  {
    startNumber: "1",
    endNumber: "20",
    unit: "",
    title: "Years of Experience",
    desc: "With over 20+ years Experience you can rest assure That your work will be done With the highest quality Craftsmanship.",
  },
  {
    startNumber: "0",
    endNumber: "100",
    unit: "",
    title: "Satisfied Clients",
    desc: "We take pride in our work and Make sure that every client is Fully satisfied before Completion.",
  },
  {
    startNumber: "1",
    endNumber: "18",
    unit: "",
    title: "Experienced Contractors",
    desc: "Our Team Consists of 18 Talented and Dedicated Contractors.",
  },
  {
    startNumber: "1",
    endNumber: "10",
    unit: "",
    title: "Services",
    desc: "We offer a wide range of services for every type of project. Confidence to move forward is the greatest service you'll gain from CR",
  },
];
